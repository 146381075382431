<template>
  <div>
    <a-drawer :title="`${form.id ? '编辑' : '新建'}Banner`" :width="720" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="标题" name="title">
              <a-input v-model:value="form.title" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="缩略图" name="icon">
              <UploadFile v-model:value="form.thumb" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="详情图" name="icon">
              <UploadFile v-model:value="form.image" />
            </a-form-item>
          </a-col>

          <!-- <a-col :span="12">
            <a-form-item label="分类说明" name="description">
              <a-input v-model:value="form.description" placeholder="请输入" />
            </a-form-item>
          </a-col> -->
        </a-row>
      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
//

import service from '@/service/service';
import UploadFile from '@/components/common/uploadFile.vue';
import { message } from 'ant-design-vue';
const rules = {
  
};
export default {
  components: {
     UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      form: {
        id: '',
        title: '',
        thumb: '',
        image: '',
        
      },
      configData:{},//支付配置参数
      visible: false,
      createLoading: false,
    }
  },
  watch: {
    current: {
      handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          for (let key in this.form) {
            temp[key] = nowCurrent[key];
          }
          this.form = temp;
          // console.log("bbbbbb")
          // console.log(this.configData)
        }
      },
      immediate: true
    }
  },
  methods: {
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },
    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    onShow() {
      this.visible = true;
    },
    async onConfirm() {
      this.createLoading = true;
      console.log(this.form);
      this.form.config = this.configData;

      const api = this.form.id ? 'edit_banner' : 'add_banner';
      const { code, msg } = await service[api]({
        ...this.form
      })
      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  }
}
</script>